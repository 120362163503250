import {reactive, watch} from "@vue/composition-api"
import {objToQuery} from "@core/utils/utils"
import {postStatisticPayload} from "@/api/lists"


export const statisticQueries = (fetch, ...ctx) => {

    const queryObj = reactive({
        filters: {
            geos: [],
            publishers: [],
            placements: [],
            platforms: [],
            advertisement_types: null,
            device_types: null,
            user_id: null,
            oses: [],
            browsers: [],
            start_date: null,
            end_date: null,
            browser_versions: [],
            os_versions: [],
            dsps: [],
            regions: [],
            campaigns: []
        },
        group: ['date'],
        select: ["sum_init", "sum_load", "fill_rate", "vtr", "sum_click", "ctr"]
    })

    const fetchStatistic = (controller) => {
        postStatisticPayload(queryObj)
            .then((res) => {})
            .catch((err) => { console.log(err) })
        return fetch(queryObj, controller)

    }

    if (ctx[0]) {
        const route = ctx[0].root.$route

        if (route.params && Object.keys(route.params).length) {
            for (const key in route.params) {
                queryObj.filters[key] = route.params[key]
            }
            // fetch(objToQuery(queryObj))
        }
    }


    return { queryObj, fetchStatistic }
}
