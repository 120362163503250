<template>
  <div ref="drop-down-filter"
       class="r-filter-cell"
       :class="[{'_changed' : proxyValue && proxyValue.length && (proxyValue[0] && proxyValue[0].pk !== 'any') || !multiple && proxyValue && !Array.isArray(proxyValue), '_placeholder' : placeholder, '_opened' : opened, '_hide' : (facets.length === 0 && !isTriedToSearch) || disabled, '_searchable': isSearchable}, blockClass]">

    <div class="r-filter-cell__bottom"
         :class="[
                {
                    '_changed' : proxyValue && proxyValue.length,
                    '_hide' : (facets.length === 0 && !isTriedToSearch),
                    '_with-error': withError,
                    '_wrong': withError && errors && errors[name] && errors[name][0],
                    '_search': isSearchable
                },
                defineClass,
            ]">

      <validation-provider :name="validateName || 'multiselect'" :rules="rules" v-slot="{ errors, ariaMsg }">
        <b-form-group :label="label" :label-for="validateName">
      <multiselect
          ref="multiselect"
          v-model="value"
          class="multiselect-validation"
          :class="{'_searchable' : isSearchable}"
          :options="isAjaxSearchable ? ajaxOptions : facets"
          :show-labels="false"
          :show-pointer="false"
          :hide-selected="false"
          :searchable="isSearchable"
          :multiple="multiple"
          :close-on-select="!multiple"
          :placeholder="placeholder"
          :open-direction="openBottom ? 'bottom' : ''"
          :label="labelName"
          :name="validateName || 'multiselect'"
          :track-by="trackBy"
          :internal-search="internalSearch"
          :clear-on-select="multiple"
          :show-no-results="isNoResult"
          :selected-label="'any'"
          :allow-empty="allowEmpty"
          @input="changeHandler"
          @open="openSelectList"
          @close="opened = true"
          @select="optionSelectHandler"
          @search-change="searchHandler"
      >
        <template slot="noOptions">
          {{ noOptionsPlaceholder || 'Список пуст'}}
        </template>

        <template slot="selection" slot-scope="{ values, search, isOpen }">
          <div v-if="proxyValue && proxyValue.length && multiple" ref="placeHolder" class="select__labels"
               :class="{'_hide' : (facets.length === 0 && !isTriedToSearch)}">
            {{ valueList }}
          </div>
        </template>
        <template slot="noResult">
          <div>{{ noResultText }}</div>
        </template>
        <template v-if="isSearchable" slot="caret" slot-scope="{ toggle }">
          <div
              :class="['multiselect__search-clear', {'_active': searchValue}]"
          ></div>
          <div v-if="isSearching" class="multiselect__spinner"></div>
          <div class="multiselect__select"
               @mousedown.prevent.stop="toggle()"
          >
          </div>
        </template>

      </multiselect>
        <small class='text-danger'>{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <div v-if="withError && errors && errors[name] && errors[name][0]"
           class="r-input__error-message"
      >
        {{ errors && errors[name] && errors[name][0] || '' }}
      </div>
    </div>
  </div>
</template>

<script>
import {BFormGroup} from "bootstrap-vue"
import Multiselect from 'vue-multiselect'
import SimpleScrollbar from 'simple-scrollbar'
import {ValidationProvider} from "vee-validate"

export default {
  name: 'MultiselectValidation',
  components: {
    Multiselect,
    ValidationProvider,
    BFormGroup,
  },

  props: {
    rules: {
      type: String
    },
    specs: {
      type: Array,
      default: () => [],
    },
    state: {
      type: Array,
      default: () => [],
    },
    facets: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Выберите',
    },
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    validateName: {
      type: String,
      default: '',
    },
    onChange: Function,
    defineClass: {
      type: String,
      default: 'r-drop-down',
    },
    blockClass: {
      type: String,
      default: '',
    },
    openBottom: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: 'pk',
    },
    labelName: {
      type: String,
      default: 'name',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    withError: Boolean,
    isNoResult: {
      type: Boolean,
      default: false,
    },
    noResultText: {
      type: String,
      default: 'Нет данных'
    },
    noOptionsPlaceholder: {
      type: String,
      default: ''
    },
    isSearchable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isAjaxSearchable: {
      type: Boolean,
      default: false,
    },
    ajaxRequest: {
      type: Function
    },
    internalSearch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      value: [],
      opened: true,
      searchValue: '',
      ajaxOptions: [],
      isSearching: false,
      isTriedToSearch: false,
    };
  },

  watch: {
    facets() {
      this.isSearching = false;
    }
  },

  computed: {
    proxyValue() {
      if(Array.isArray(this.value)) return this.value;
      if(!this.value) return [];

      const arr = [];
      arr.push(this.value)
      return arr;
    },
    valueArray() {
      let arr = [];

      if (Array.isArray(this.proxyValue)) {
        this.proxyValue.forEach(item => {
          arr.push(item[this.trackBy]);
        });
      } else {
        arr.push(this.proxyValue[this.trackBy]);
      }

      return arr;
    },

    valueList() {
      let label = '';

      this.proxyValue.forEach(item => {
        if (this.facets.includes(item[this.trackBy]))
          label += `${item[this.labelName]}, `;
      });

      if (!label) {
        label = this.proxyValue.reduce((acc, item) => acc += `${item[this.labelName]}, `, '');
      }

      return label.slice(0, -2);
    },

    initialValue() {
      if (!(this.facets && this.facets.length) || !(this.state && this.state.length)) return []
      return [].concat.apply([], this.facets.filter(opt => this.state.includes(opt[this.trackBy])));
    },
  },

  created() {
    this.$parent.$on('resetFilters', () => {
      this.value = [];
    })

    if(this.isAjaxSearchable) {
      this.initAjaxOptions();
    }

    if (this.initialValue && this.initialValue.length) {
      this.value = this.initialValue;
    }
  },

  updated() {
      if (this.initialValue && this.initialValue.length) {
          this.value = this.initialValue;
      }
  },

  mounted() {
    if (this.state && this.state.length)
      this.value = this.specs.filter(opt => this.state.includes(opt[this.trackBy]));

    // this.initScroll();
  },

  methods: {
    optionSelectHandler() {
      // Ограничение ширины, чтобы фильтр не разъезжался, когда выбрано большое количество пунктов в селекте
      // const placeHolderToFix = $(this.$refs.placeHolder);
      // const placeHolderToFixWidth = placeHolderToFix.outerWidth();
      // placeHolderToFix.css('width', placeHolderToFixWidth);
    },

    changeHandler() {
      // if (!this.proxyValue || this.proxyValue?.length === 0) {
      //     $(this.$refs.placeHolder).css('width', 'auto');
      // }

      if (this.withError) {
        this.$emit('remove-error', this.name);
      }

      if (this.onChange) this.onChange(this.valueArray, this.name);
    },

    searchHandler(searchQuery) {
      if(!(searchQuery === '' || searchQuery.length >= 2)) return;
      this.isTriedToSearch = true;
      this.isSearching = true;
      this.$emit('search', [this.name, searchQuery])
    },

    initAjaxOptions() {
      this.ajaxRequest()
          .then(res => this.ajaxOptions = res.data.data)
          .catch(e => console.log(e))
    },

    initScroll() {
      let optionListWrap = this.$refs['drop-down-filter'].querySelector('.multiselect__content-wrapper');
      optionListWrap.setAttribute('ss-container', true);
      SimpleScrollbar.initAll();
    },

    scrollContainerFix(timeout = 100) {
      let optionListWrap = this.$refs['drop-down-filter'].querySelector('.multiselect__content-wrapper');
      let optionList = this.$refs['drop-down-filter'].querySelector('.multiselect__content');


      setTimeout(() => {
        optionListWrap.style.height = optionList.clientHeight + 'px';
      }, timeout);
    },

    openSelectList() {
      // this.opened = !this.opened;
      //
      // if (!this.opened) return;

      this.scrollContainerFix();
    },

    close() {
      this.$refs.multiselect.toggle();
    }
  },
};
</script>

<style lang="scss">
$primary: #7367f0;

label {
  font-size: 1rem;
}
.multiselect-validation {
  line-break: normal;
  min-height: unset;

  &._searchable {

    & .multiselect__spinner {
      width: 20px !important;
      right: 12px !important;
      top: 4px !important;
      z-index: 999;

      &:before {
        border-top-color: $primary !important;
      }

      &:after {
        border-top-color: $primary !important;
      }
    }

    .multiselect__tags {

      & .select__labels {
        display: none;
      }
      display: block;
      padding: 12px 0 0 12px;
    }

    .multiselect__input {
      display: block;
      padding: 0px !important;
    }

    .multiselect__option {
      font-size: 14px;
    }

    .multiselect-enter .multiselect-leave-active {
      opacity: 1 !important;
    }
  }

  .multiselect__input {
    display: none;
    width: 100% !important;
    position: static !important;
    padding: inherit !important;
  }
  .multiselect__content-wrapper {
    display: block !important;
    height: auto !important;
    max-height: 300px !important;
    position: static;
    border-radius: 5px;
    border-top: 1px solid #e8e8e8;
  }
  .multiselect__placeholder {
    display: none !important;
  }
  .multiselect__tags {
    display: none;
    border-radius: 5px;
    border-bottom: none;
  }
  .multiselect--above .multiselect__content-wrapper {
    bottom: inherit;
    border-radius: 0;
    border-bottom: none;
  }
  .multiselect__select {
    display: none;
  }
}
</style>

<!--<style lang="scss">-->
<!--.r-filter-cell {-->

<!--  &._opened,-->
<!--  &._searchable {-->

<!--    .multiselect__input {-->

<!--      &::placeholder {-->
<!--        color: transparent;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->

<!--.r-filter-cell__bottom._search-->
<!--.multiselect&#45;&#45;active {-->

<!--  & .multiselect__input {-->
<!--    position: absolute;-->
<!--    top: 0;-->
<!--    left: 0;-->
<!--    max-width: 75%;-->
<!--    padding: 24px;-->
<!--    height: 100%;-->
<!--    background: transparent;-->
<!--    font-weight: 500;-->
<!--  }-->

<!--  & .select__labels {-->
<!--    opacity: 0;-->
<!--  }-->

<!--  & .multiselect__search-clear {-->
<!--    display: inline-block;-->
<!--  }-->
<!--}-->

<!--.multiselect__search-clear {-->
<!--  position: absolute;-->
<!--  display: none;-->
<!--  right: 32px;-->
<!--  margin-right: 12px;-->
<!--  top: 50%;-->
<!--  height: 10.86px;-->
<!--  width: 10.86px;-->
<!--  padding: 0;-->
<!--  border-radius: 50%;-->
<!--  background-color: #f4f6fb;-->
<!--  -webkit-transform: translateY(-50%);-->
<!--  transform: translateY(-50%);-->
<!--  z-index: 2;-->
<!--  text-align: center;-->
<!--  transition: .2s ease-in-out;-->

<!--  &._active::before {-->
<!--    transition: .3s ease-in-out;-->
<!--    background-image: url('./../../images/icns/com-cancel-active.svg');-->
<!--  }-->

<!--  &::before {-->
<!--    content: '';-->
<!--    position: absolute;-->
<!--    right: 0;-->
<!--    top: 50%;-->
<!--    left: 50%;-->
<!--    width: 100%;-->
<!--    height: 100%;-->
<!--    margin: 0;-->
<!--    background-image: url('./../../images/icns/com-cancel-inactive.svg');-->
<!--    background-repeat: no-repeat;-->
<!--    background-position: 50%;-->
<!--    background-size: contain;-->
<!--    border: none;-->
<!--    transform: translateZ(0) translate(-50%, -50%);-->
<!--    transition: .3s ease-in-out;-->
<!--    z-index: 2;-->
<!--  }-->
<!--}-->
<!--</style>-->
