import api from '../libs/axios';

export const fetchPublisherStatistics = (payload) => api.post('/api/statistic/publisher', payload)

export const fetchStatisticsDSP = (query) => api.get(`/api/statistic/dsp${query ? `?${query}` : ''}`)

export const fetchCampaignStatistics = (query) => api.get(`/api/statistic/campaign${query ? `?${query}` : ''}`)

export const fetchFinance = (query) => api.get(`/api/finance${query ? `?${query}` : ''}`)

export const fetchLogs = () => api.get('/api/activity_log')

export const fetchAdminStatistics = (query) => api.get(`/api/statistic/admin${query ? `?${query}` : ''}`)

export const fetchManagerStatistics = (query) => api.get(`/api/statistic/manager${query ? `?${query}` : ''}`)

export const fetchManagerStatisticsExperimental = (payload, controller) => api.post('/api/statistic/manager', payload, {
    signal: controller.signal
})

export const fetchManagerDSPStatistics = (payload) => api.post('/api/statistic/manager_dsp', payload)

export const fetchAdvertiserStatistics = (payload) => api.post('/api/statistic/advertiser', payload)
