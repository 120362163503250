import { render, staticRenderFns } from "./FilterBadges.vue?vue&type=template&id=538dcc48&"
import script from "./FilterBadges.vue?vue&type=script&lang=js&"
export * from "./FilterBadges.vue?vue&type=script&lang=js&"
import style0 from "./FilterBadges.vue?vue&type=style&index=0&id=538dcc48&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports