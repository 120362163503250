<template>
  <div>
  <div class="fields">
    <div v-for="category in fields" class="fields__category">
      <p>{{category.label}}</p>
      <b-form-checkbox
          v-for="checkbox in category.fields"
          class="fields__checkbox"
          stacked
          v-model="selected"
          :disabled="disableMethod(checkbox)"
          :value="checkbox.key"
      >{{checkbox.label}}
      </b-form-checkbox>
    </div>
  </div>
  </div>
</template>

<script>
import {BFormCheckbox, BFormCheckboxGroup} from "bootstrap-vue"
export default {
  name: 'StatisticFieldsModal',
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    state: {
      type: Array,
      default: () => [],
    },
    disableMethod: Function,
    },
    components: {
      BFormCheckbox,
      BFormCheckboxGroup
    },

    data() {
      return {
        selected: [],
      }
    },

  created() {
    if(this.state && this.state.length) this.selected = this.state;
  },

  watch: {
    selected() {
      this.$emit('pick-fields', this.selected);
    }
  },

}
</script>

<style lang="scss">

.fields {
  display: flex;
  gap: 30px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }

  &__category {
    flex: 1 1 0;
  }

  &__checkbox {
    &.custom-checkbox {
      margin-bottom: 8px;
    }
}
}
</style>
