<template>
  <div>
    <label v-if="label" :for="name">{{label}}</label>
  <div :id="name" class="multiselect__container" :class="{'_small': isSmall}">
    <div v-if="isSearchable" class="multiselect__input-wrapper">
      <BFormInput class="multiselect__search-input" v-model="search" @input="searchHandler" :placeholder="placeholder" />
      <BSpinner v-if="isSearching" class="multiselect__loader" variant="primary" small />
    </div>
    <div class="multiselect__items">
      <vue-simple-scrollbar ref="scroll">
          <div ref="list" class="multiselect__items-wrapper">
          <BFormCheckboxGroup
            v-model="value"
            stacked
            @change="changeHandler"
          >
<!--            <BFormCheckbox v-if="isPaginated && !single" value="all" @change="pickAllSignal">Выбрать все</BFormCheckbox>-->
            <BFormCheckbox v-for="checkbox in facets" :value="checkbox[trackBy]" :disabled="disableMethod(checkbox)">{{checkbox.name}}</BFormCheckbox>
          </BFormCheckboxGroup>
          </div>
      </vue-simple-scrollbar>
    </div>
  </div>
  </div>
</template>

<script>
import {BFormCheckboxGroup,BFormCheckbox, BFormInput, BSpinner} from "bootstrap-vue"
import VueSimpleScrollbar from 'vue-simple-scrollbar'
import '../../node_modules/vue-simple-scrollbar/dist/vue-simple-scrollbar.css'

export default {
  name: 'MultiselectCheckboxes',
  components: {
    BFormCheckboxGroup,
    BFormInput,
    BSpinner,
    BFormCheckbox,
    VueSimpleScrollbar
  },

  props: {
    state: {
      type: Array,
      default: () => [],
    },
    facets: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Введите несколько символов для поиска',
    },
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    onChange: Function,
    disableMethod: {
      type: Function,
      default: () => false,
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    labelName: {
      type: String,
      default: 'name',
    },
    isPaginated: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    lists: {
      type: Object,
      default: () => {}
    },
    single: {
      type: Boolean,
      default: false
    },
    isSmall: Boolean,
    emitList: Boolean,
  },

  data() {
    return {
      value: [],
      search: '',
      opened: true,
      searchValue: '',
      ajaxOptions: [],
      isSearching: false,
      isTriedToSearch: false,
    };
  },

  watch: {
    facets() {
      this.isSearching = false;
    }
  },

  computed: {
    proxyValue() {
      if(Array.isArray(this.value)) return this.value;
      if(!this.value) return [];

      const arr = [];
      arr.push(this.value)
      return arr;
    },
    valueArray() {
      let arr = [];

      if (Array.isArray(this.proxyValue)) {
        this.proxyValue.forEach(item => {
          arr.push(item[this.trackBy]);
        });
      } else {
        arr.push(this.proxyValue[this.trackBy]);
      }

      return arr;
    },

    valueList() {
     return this.initialValue.map((item) => item.name).join(', ');
    },

    initialValue() {
      if (!(this.facets && this.facets.length) || !(this.state && this.state.length)) return []
      return [].concat.apply([], this.facets.filter(opt => this.state.includes(opt[this.trackBy])));
    },
  },

  created() {
    this.$parent.$on('resetFilters', () => {
      this.value = [];
    })

    if(this.isAjaxSearchable) {
      this.initAjaxOptions();
    }

    if (this.state && this.state.length) {
      this.value = this.state
    }
  },

  updated() {
    // if (this.initialValue && this.initialValue.length) {
    //   this.value = this.initialValue.map(item => item[this.trackBy]);
    // }
  },

  mounted() {
    if(this.isPaginated) {
        this.$nextTick(() => {
          const ssContent = this.$refs['scroll'].$el["data-simple-scrollbar"].el;
          const list = this.$refs['list'].$el;

          const observer = (e) => {
            if((e.target.scrollTop + e.target.clientHeight >= this.$refs['list'].clientHeight) && !this.isSearching) {
              if(!this.lists[this.name][1].value) return;
              this.isSearching = true;
              this.$emit('paginate', this.name);
            }
          }

          ssContent.addEventListener('scroll', observer)


        })
    }

  },

  methods: {
    pickAllSignal() {
      this.$emit('pick-all', this.name);
    },
    changeHandler(e) {
      if(this.single) this.value = [e.at(-1)]
      if(this.emitList) {
        this.$nextTick(() => {
          let valueList = ''
          valueList = this.initialValue.map((item) => item.name).join(', ');
          this.onChange(this.value, this.name, valueList);
        })
      } else {
        this.onChange(this.value, this.name);
      }
    },

    deselectAll() {
      this.value = [];
    },

    searchHandler(searchQuery) {
      if (!(searchQuery === '' || searchQuery.length >= 2)) return;
      this.isSearching = true;
      if (searchQuery === '') {
        this.$emit('search', [this.name, '', this.value.join(',')])
      } else {
      this.$emit('search', [this.name, searchQuery])
      }
    },

    initScroll() {
      let optionListWrap = this.$refs['list'];
      optionListWrap.setAttribute('ss-container', true);
      SimpleScrollbar.initAll();
    },
  },
};
</script>

<style lang="scss" scoped>

.multiselect {

  &__container {
    max-height: 400px;
    border-radius: 0.357rem;

    &._small {
      max-height: 300px;

      & .multiselect__items {
        height: 160px;
      }
    }
  }

  &__search-input {
    font-size: 16px;
    border: 1px solid #d8d6de;
  }

  &__items {
    height: 320px;

    @media only screen and (max-width: 576px) {
      height: 160px;
    }
  }

  &__items-wrapper {
    margin-top: 12px;
    padding-left: 12px;
  }

  &__loader {
    position: absolute;
    top: 35%;
    right: 16px;
  }

  &__input-wrapper {
    position: relative;
  }
}
</style>

<style lang="scss">
.multiselect {
  &__items-wrapper {

    & .custom-checkbox {
      margin-bottom: 8px;
    }
  }
}
</style>

