import {onMounted, onUnmounted, ref} from '@vue/composition-api'

export function infiniteScroll(loadMoreData, isLoading) {

    const scroll = ref(null)

    const observer = () => {
        if (!scroll.value) return
        if (document.documentElement.scrollTop + window.innerHeight >= scroll.value.offsetHeight && !isLoading.value) {
            loadMoreData()
        }
    }

    onMounted(() => {
        window.addEventListener('scroll', observer)
    })

    onUnmounted(() => {
        window.removeEventListener('scroll', observer)
    })

    return { scroll }
}
