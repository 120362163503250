var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"drop-down-filter",staticClass:"r-filter-cell",class:[{'_changed' : _vm.proxyValue && _vm.proxyValue.length && (_vm.proxyValue[0] && _vm.proxyValue[0].pk !== 'any') || !_vm.multiple && _vm.proxyValue && !Array.isArray(_vm.proxyValue), '_placeholder' : _vm.placeholder, '_opened' : _vm.opened, '_hide' : (_vm.facets.length === 0 && !_vm.isTriedToSearch) || _vm.disabled, '_searchable': _vm.isSearchable}, _vm.blockClass]},[_c('div',{staticClass:"r-filter-cell__bottom",class:[
              {
                  '_changed' : _vm.proxyValue && _vm.proxyValue.length,
                  '_hide' : (_vm.facets.length === 0 && !_vm.isTriedToSearch),
                  '_with-error': _vm.withError,
                  '_wrong': _vm.withError && _vm.errors && _vm.errors[_vm.name] && _vm.errors[_vm.name][0],
                  '_search': _vm.isSearchable
              },
              _vm.defineClass ]},[_c('validation-provider',{attrs:{"name":_vm.validateName || 'multiselect',"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var ariaMsg = ref.ariaMsg;
return [_c('b-form-group',{attrs:{"label":_vm.label,"label-for":_vm.validateName}},[_c('multiselect',{ref:"multiselect",staticClass:"multiselect-validation",class:{'_searchable' : _vm.isSearchable},attrs:{"options":_vm.isAjaxSearchable ? _vm.ajaxOptions : _vm.facets,"show-labels":false,"show-pointer":false,"hide-selected":false,"searchable":_vm.isSearchable,"multiple":_vm.multiple,"close-on-select":!_vm.multiple,"placeholder":_vm.placeholder,"open-direction":_vm.openBottom ? 'bottom' : '',"label":_vm.labelName,"name":_vm.validateName || 'multiselect',"track-by":_vm.trackBy,"internal-search":_vm.internalSearch,"clear-on-select":_vm.multiple,"show-no-results":_vm.isNoResult,"selected-label":'any',"allow-empty":_vm.allowEmpty},on:{"input":_vm.changeHandler,"open":_vm.openSelectList,"close":function($event){_vm.opened = true},"select":_vm.optionSelectHandler,"search-change":_vm.searchHandler},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var values = ref.values;
          var search = ref.search;
          var isOpen = ref.isOpen;
return [(_vm.proxyValue && _vm.proxyValue.length && _vm.multiple)?_c('div',{ref:"placeHolder",staticClass:"select__labels",class:{'_hide' : (_vm.facets.length === 0 && !_vm.isTriedToSearch)}},[_vm._v(" "+_vm._s(_vm.valueList)+" ")]):_vm._e()]}},{key:"caret",fn:function(ref){
          var toggle = ref.toggle;
return (_vm.isSearchable)?[_c('div',{class:['multiselect__search-clear', {'_active': _vm.searchValue}]}),(_vm.isSearching)?_c('div',{staticClass:"multiselect__spinner"}):_vm._e(),_c('div',{staticClass:"multiselect__select",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return toggle()}}})]:undefined}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('template',{slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.noOptionsPlaceholder || 'Список пуст')+" ")]),_c('template',{slot:"noResult"},[_c('div',[_vm._v(_vm._s(_vm.noResultText))])])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),(_vm.withError && _vm.errors && _vm.errors[_vm.name] && _vm.errors[_vm.name][0])?_c('div',{staticClass:"r-input__error-message"},[_vm._v(" "+_vm._s(_vm.errors && _vm.errors[_vm.name] && _vm.errors[_vm.name][0] || '')+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }