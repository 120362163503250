<template>
  <div class="filter-badges" ref="tags">
      <BBadge class="filter-badge" v-for="tag in tags" variant="primary">{{ tag }}</BBadge>
  </div>
</template>
<script>
import {BBadge} from "bootstrap-vue"
export default {
  name: 'FilterBadges',
  components: {
    BBadge
  },
  props: {
    facets: {
      type: Array,
      default: () => []
    },
    name: {
      type: String
    },
    state: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      maxLetters: 0
    }
  },

  computed: {
    tags() {
      //for non paginated
      let names = [];
      if(typeof this.facets[2] === 'undefined') {
        if (!(this.state && this.state.length)) return []
        const picked = this.facets[0].value.filter(item => this.state.includes(item.id));
        names = picked.map((item => item.name))
      } else {
        if (!(this.state && this.state.length && this.facets[2])) return [];
        const picked = this.facets[2].value.filter(item => this.state.includes(item.id));
        names = picked.map((item => item.name))
      }
        return names.join(' ').length > this.maxLetters ? [names.length] : names
    }
  },
  mounted() {
    if (this.$refs.tags) {
      this.maxLetters = Math.round(this.$refs.tags.clientWidth / 11) + 1;
    }
  },
}
</script>

<style lang="scss">
.filter-badges .filter-badge:not(:first-child) {
  margin-left: 4px;
}
</style>
