<template>
  <validation-provider v-slot='{ errors }' :name='label' :vid='vid' :rules='rules'>
    <b-form-group :label='labelHidden ? "" : label' :label-for='id'>
      <slot :errors='errors' :state='errors.length > 0 ? false : null' />
      <small class='text-danger'>{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>

import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'FormInputWrap',
  components: {
    BFormGroup,
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    labelHidden: Boolean,
    vid: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
      default: undefined
    },
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e)
    }
  }
}

</script>
