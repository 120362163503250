<template>
  <div>
    <vue-apex-charts v-if="chartOptionsComputed"
        type="line"
        height="400"
        :options="chartOptionsComputed"
        :series="chartData"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { lineChartOptions } from './chartOptions'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    statistic: {
      type: [Number, String],
      required: false,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartGroup: {
      type: Array,
      default: () => []
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  computed: {
    chartOptionsComputed() {
      this.chartOptions.xaxis.categories = this.chartGroup;

      return { ...this.chartOptions }
    },
  },
  methods: {
    gradientToColor(color) {
      const gradientToColors = {
        primary: '#A9A2F6',
        success: '#55DD92',
        warning: '#ffc085',
        danger: '#F97794',
        info: '#59E0F0',
        secondary: '#B4B9BF',
        light: '#D0D4DB',
        dark: '#919191',
      }

      return gradientToColors[color]
    },
  },
}
</script>
