export default function(value) {
    if(!value) return 0;
    if(value === (5e-324)) {
        return value.toFixed(0);
    }
    if( value <= 100) {
        return Number.isInteger(value) ? value : value.toFixed(1);
    }
    let val = Math.abs(value)
    if (val >= 100000) {
        return (val / 1000000).toFixed(1) + ' M';
    }
    return val.toFixed(0);
}
