<template>
  <div class="filters">
      <div class="filters__filter" v-for="filter in filtersOptions">
    <b-list-group-item class="d-flex text-nowrap align-items-center" :class="{'_invalid': errors && proxyNames && errors[proxyNames[filter.value]] && errors[proxyNames[filter.value]].length}" @click="setFilter(filter.value)" :active="value === filter.value">
      {{ filter.text }}
      <div class="ml-1 w-100">
        <FilterBadges :facets="facets[filter.value]" :state="proxyNames ? state[proxyNames[filter.value]] : state[filter.value]" :name="filter.value"></FilterBadges>
<!--      <BBadge v-for="badge in state[filter.value]">{{badge}}</BBadge>-->
      </div>
    </b-list-group-item>
      </div>
  </div>
</template>

<script>
import {BBadge, BListGroup, BListGroupItem} from "bootstrap-vue"
import FilterBadges from "@/components/FilterBadges"

export default {
  name: 'ToFilterSelect',
  components: {
    FilterBadges,
    BBadge,
    BListGroupItem
  },

  props: {
    filtersOptions: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Object,
      default: () => {}
    },
    //for validation highlighting
    proxyNames: {
      type: Object,
      default: () => {}
    },
    state: {
      type: Object,
      default: () => {}
    },
    facets: {
      type: Object,
      default: () => {}
    },
    modelValue: {
      type: String,
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:model-value', value)
      }
    }
  },

  methods: {
    setFilter(event) {
      this.value = event;
    }
  }
}
</script>

<style lang="scss">
.filters {

  & .filters__filter:first-child {
    & .list-group-item {
      border-top-left-radius: 0.357rem;
      border-top-right-radius: 0.357rem;
    }
  }
  & .filters__filter:last-child {
    & .list-group-item:last-child {
      border-bottom-left-radius: 0.357rem;
      border-bottom-right-radius: 0.357rem;
    }
  }
}

.list-group-item {
  padding: 0.5rem 1.25rem;

  &.active {

    & .badge {
      background-color: white;
      color: #7367f0;
    }
  }

  &._invalid {
    border: 1px solid #ea5455;
    background-color: #ea5455;
    color: white;

    & .badge {
      color: #7367f0;
    }
  }
}
</style>
