import labelFormatter from "@/use/labelFormatter"

const fieldsIgnoreList = ['group']
export const makeChartsData = (chartGroup, chartOptions, chartsSeries, data) => {
    chartGroup.value = data.value.result.map(obj => obj.group)
    let y = [];
    chartOptions.yaxis = [];

    data.value.field.forEach((field, index) => {

        if (fieldsIgnoreList.includes(field.key)) return

        const fieldObj = {}
        const yAxis = data.value.result.map(obj => obj[field.key])
        fieldObj.key = field.key
        fieldObj.label = field.label
        fieldObj.series = [{
            name: field.label,
            data: [...yAxis]
        }]
        y.push(fieldObj)

        let maxY;

        if (!field.label.includes(', %')) {
            maxY = Math.round(Math.max(...yAxis) * 1.25)
        } else {
            const checkMax =  Math.ceil(Math.max(...yAxis) * 1.25)
            maxY = checkMax > 100 ? 100 : checkMax;
        }

        chartOptions.yaxis.push(
            {
                opposite: !!field.label.includes(', %'),
                labels:{show: true,
                    formatter: labelFormatter
                },
                tickAmount: 4,
                logarithmic: false,
                title: {text: field.label,},
                min: 0,
                max: maxY
            })
    })
    let series = [];
    series = y.map((serie) => {
        return {
            name: serie.label,
            data: serie.series[0].data
        }
    })
    chartsSeries.value = series;
}

export const makeMultiChartsData = (chartGroup, chartOptions, chartsSeries, data) => {

    chartGroup.value = data.value.result[0].data.map(obj => obj.group1)
    let y = [];
    chartOptions.yaxis = [];

    data.value.field.forEach((field, index) => {

        if (fieldsIgnoreList.includes(field.key)) return

        const fieldObj = {}

        fieldObj.key = field.key
        fieldObj.label = field.label
        fieldObj.series = []

        data.value.result.forEach((subgroup) => {
            const yAxis = subgroup.data.map(obj => obj[field.key])
            fieldObj.series.push({
                name: field.label + ' ' + subgroup.key,
                data: [...yAxis]
            })
        })

        y.push(fieldObj);

        let maxY;

        const allValues = fieldObj.series.map(serie => serie.data).flat();


        if (!field.label.includes(', %')) {
            maxY = Math.round(Math.max(...allValues) * 1.25)
        } else {
            const checkMax =  Math.ceil(Math.max(...allValues) * 1.25)
            maxY = checkMax > 100 ? 100 : checkMax;
        }

        fieldObj.series.forEach((subserie, index) => {
            if(index === 0) {
                chartOptions.yaxis.push(
                    {
                        seriesName: subserie.name,
                        opposite: !!field.label.includes(', %'),
                        labels:{show: true,
                            formatter: labelFormatter
                        },
                        tickAmount: 4,
                        logarithmic: false,
                        title: {text: field.label,},
                        min: 0,
                        max: maxY,
                    })
            } else {
                chartOptions.yaxis.push({
                    seriesName: fieldObj.series[0].name,
                    show: false,
                    labels:{show: false,
                        formatter: labelFormatter
                    },
                })
            }
        })
    })
    let series = [];

    y.forEach((item) => {
        series.push(...item.series);
    })
    chartsSeries.value = series;
}
