<template>
  <div>

    <download-excel style="display: none" ref="excel" :data="toJSONData" :name="exportName" :export-fields="toJSONFields" :before-generate="generateExcel" :json-meta="[[{key: 'charset',value: 'utf-8', }]]">
    </download-excel>


    <b-table v-if="!statistics" :items="[]" :fields="fields">

    </b-table>
    <b-table v-else class="publisher-statistic__table" responsive sticky-header="600px" :items="formattedStatistics" :sort-by="sortBy" :fields="formattedFields">
      <template v-if="multiGroup" #cell(group1)="row">
        {{row.item.group1}} {{row.item.group}}
      </template>

    <template #cell(type)="row">
      <!--      {{ row.item.advertisement_type.map((type) => type.name).join(', ') }}-->
      <b-badge v-for="type of row.item.advertisement_types" :key="type.id" class="badge" variant="primary">
        {{ type.name }}
      </b-badge>
    </template>


    <template #custom-foot="row">
      <tr class="publisher-statistic__sum">
        <td v-for="field in formattedFields" :key="field.key">

          <template v-if="field.key === 'group' && Object.keys(sums).length">
              Итого
          </template>

          <template v-else-if="field.belongsTo && Object.keys(sums).length">
            {{ sums[field.key] | formatNumber }} ({{ sums[field.belongsTo] }}%)
          </template>

          <template v-else>
          {{ sums[field.key] | formatNumber }}
          </template>

        </td>
      </tr>
    </template>
  </b-table>
  </div>
</template>

<script>
import { BTable, BBadge, BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { MoreVerticalIcon } from 'vue-feather-icons'
import MultiselectWithValidation from "@/components/MultiselectWithValidation"

export default {
  components: {
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    MoreVerticalIcon,
    MultiselectWithValidation
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    statistics: {
      type: Array,
      required: false,
      default: () => []
    },
    sums: {
      type: Object,
      default: () => []
    },
    multiGroup: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Array,
      default: () => [],
    }
  },

  created() {
    this.initialSortState = this.$store.state.statistics.sortedFields;

    this.pickedFields = (this.initialSortState.length && this.initialSortState.map(obj => obj.key)) || this.fields?.map(obj => obj.key);
    if(this.statistics && this.statistics.length) {
      this.formattedStatistics = JSON.parse(JSON.stringify(this.statistics));
      if(this.multiGroup) {
        this.formattedStatistics = this.formattedStatistics.map(item => item.data).flat();
        this.sortBy = 'group1';
      }
      this.$parent.$parent.$on('exportXls', () => {
        this.$refs.excel.generate();
      })
    }
  },
  data() {
    return {
      formattedStatistics: [],
      // formattedFields: [],
      pickedFields: null,
      initialSortState: [],
      sortBy: 'group',
      toJSONData: [],
      toJSONFields: {},
    }
  },
  computed: {
    // sortedFields() {
    //   const fields = this.fields.filter(obj => this.pickedFields.indexOf(obj.key) !== -1);
    //
    //   this.$store.dispatch('statistics/SET_FIELDS', fields);
    //   return fields
    // },
    exportName() {
      return `Отчет от ${new Date().toJSON().slice(0, 10)}`
    },
    formattedFields() {
      if(!this.multiGroup) {
      return this.fields.map((item) => {
        if(item.key === 'group') return {
          key: item.key,
          label: item.label,
          sortable: true,
        }

        if(item.belongsTo) {
          return {
            key: item.key,
            label: item.label,
            belongsTo: item.belongsTo,
            formatter:  (val, key, data) => {
              return this.fieldsWithAdditionsFormatter(val, key, data, item);
            },
            sortable: true,
          }
        }

        return {
          key: item.key,
          label: item.label,
          formatter:  this.$options.filters.formatNumber,
          sortable: true,
        }
      })
    } else {
        return this.fields.map((item) => {
          if(item.key === 'group') return {
            key: 'group1',
            label: item.label,
            sortable: true,
          }

          if(item.belongsTo) {
            return {
              key: item.key,
              label: item.label,
              belongsTo: item.belongsTo,
              formatter:  (val, key, data) => {
                return this.fieldsWithAdditionsFormatter(val, key, data, item);
              },
              sortable: true,
            }
          }

          return {
            key: item.key,
            label: item.label,
            formatter:  this.$options.filters.formatNumber,
            sortable: true,
          }
        })
      }
    },
  },
  methods: {
    fieldsWithAdditionsFormatter(value, key, data, item) {
      const general = this.$options.filters.formatNumber(value);
      return `${general} (${data[item.belongsTo]}%)`
    },
    handleEdit(id) {
      this.$emit('edit', id)
    },
    handleToggleActive(data) {
      this.$emit('toggle-active', data)
    },
    sortFields(value, name) {
      this.pickedFields = value;
    },
    generateExcel() {
      this.toJSONData =  JSON.parse(JSON.stringify(this.formattedStatistics));
        let fields = JSON.parse(JSON.stringify(this.formattedFields));
        let obj = {}
        if(!this.multiGroup) {
          fields.forEach((item) => {
            obj[item.label] = {
              field: item.key,
              callback: (val) => {
                if (typeof val !== 'number') return val;
                return (val + '').replace('.', ',')
              }
            }
          })
        } else {
         obj[this.group[0]] = 'group1';
         obj[this.group[1]] = 'group';
          fields.forEach((item) => {
            if(item.key === 'group1') return;
            obj[item.label] = {
              field: item.key,
              callback: (val) => {
                if (typeof val !== 'number') return val;
                return (val + '').replace('.', ',')
              }
            }
          })
        }
        this.toJSONFields = obj;
      }
    }
}
</script>

<style lang="scss" scoped>
.badge {
  margin: 3px;
}
</style>

<style lang="scss">
.publisher-statistic {

  &__table {

    & th {
      white-space: nowrap;
    }

    & td {
      white-space: nowrap;
    }
  }

  &__sum {
    font-weight: 600;
    border-top: 5px solid #f3f2f7;
  }
}
</style>
