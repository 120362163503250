<template>
  <validation-provider v-slot='{ errors }' :name='label' :vid='vid' :rules='rules'>
    <b-form-group :label='label' :label-for='id'>
      <b-form-input
        :placeholder='placeholder'
        :id='id'
        :value='value'
        @input='handleInput'
        @blur="handleBlur"
        :state='errors.length > 0 ? false : null'
      />
      <small class='text-danger'>{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>

import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  props: {
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    formGroupClass: String,
    vid: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
      default: undefined
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  components: {
    BFormInput,
    BFormGroup,
    ValidationProvider,
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e)
    },
    handleBlur(e) {
      this.$emit('blur', e);
    }
  }
}

</script>
